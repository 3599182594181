import cn from "../utils/utils.cn";
import type { ReactNode } from "react"

export type CardProps = {
  children: ReactNode
  className?: string
  style?: React.CSSProperties
};

function Card(props: CardProps) {
  const { children, className, style } = props
  return (
    <div className={cn("p-6 rounded-lg shadow-md bg-gray-800", className)}
      style={style}>
      {children}
    </div>
  )
}

export default Card
