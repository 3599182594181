import { useEffect, useState } from "react"

export type WaterLevelProps = {
  showCurrentWaterLevel?: boolean,
  showAbnormalWaterLevel?: boolean
}

function WaterLevel(props: WaterLevelProps) {
  const {
    showCurrentWaterLevel,
    showAbnormalWaterLevel,
  } = props

  const [waterLevelData, setWaterLevelData] = useState(null)

  useEffect(() => {
    (async () => {
      const response = await fetch("https://hydapi.nve.no/api/v1/Observations?StationId=3.8.0&Parameter=1000&ResolutionTime=0", {
        headers: {
          "accept": "application/json",
          "X-API-Key": "pVb/D7H5MUaVyfLSJvtSvg=="
        }
      })
      const data = await response.json()
      setWaterLevelData(data)
    })()
  }, [])

  const currentYear = new Date().getFullYear()
  const lastYear = currentYear - 1

  const currentWaterLevel = waterLevelData?.data[0].observations[0].value

  const calculateAbnormalWaterLevelInCentimeters = (normalWaterLevel) => {
    return Math.round((currentWaterLevel - normalWaterLevel) * 100).toString().substring(0, 5)
  }

  const checkIfTodaysDateIsBetweenTwoDates = (startDate: Date, endDate: Date) => {
    const todaysDate = new Date(new Date().setHours(0, 0, 0, 0))
    startDate.setHours(0, 0, 0, 0)
    endDate.setHours(23, 59, 59, 0)
    return todaysDate >= startDate && todaysDate <= endDate
  }

  const getAbnormalWaterLevel = () => {
    if (checkIfTodaysDateIsBetweenTwoDates(new Date(`11.01.${lastYear}`), new Date(`04.14.${currentYear}`)))
      return calculateAbnormalWaterLevelInCentimeters(24.2)
    if (checkIfTodaysDateIsBetweenTwoDates(new Date(`04.15.${currentYear}`), new Date(`05.31.${currentYear}`)))
      return calculateAbnormalWaterLevelInCentimeters(24.6)
    if (checkIfTodaysDateIsBetweenTwoDates(new Date(`05.01.${currentYear}`), new Date(`08.15.${currentYear}`)))
      return calculateAbnormalWaterLevelInCentimeters(24.9)
    if (checkIfTodaysDateIsBetweenTwoDates(new Date(`08.16.${currentYear}`), new Date(`08.30.${currentYear}`)))
      return calculateAbnormalWaterLevelInCentimeters(24.5)
    if (checkIfTodaysDateIsBetweenTwoDates(new Date(`09.01.${currentYear}`), new Date(`10.31.${currentYear}`)))
      return calculateAbnormalWaterLevelInCentimeters(24.2)
    return "NaN"
  }

  const getCurrentWaterLevel = () => {
    if (waterLevelData) return currentWaterLevel.toString().substring(0, 5)
    else return "Laster..."
  }

  if (showCurrentWaterLevel) return (getCurrentWaterLevel())
  if (showAbnormalWaterLevel) return (getAbnormalWaterLevel())

  return (
    <>NaN</>
  )
}

export default WaterLevel
