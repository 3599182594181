import cloudy from "@bybas/weather-icons/design/fill/animation-ready/cloudy.svg";
import clearDay from "@bybas/weather-icons/design/fill/animation-ready/clear-day.svg";
import clearNight from "@bybas/weather-icons/design/fill/animation-ready/clear-night.svg";
import partlyCloudyDay from "@bybas/weather-icons/design/fill/animation-ready/partly-cloudy-day.svg";
import partlyCloudyNight from "@bybas/weather-icons/design/fill/animation-ready/partly-cloudy-night.svg";
import rain from "@bybas/weather-icons/design/fill/animation-ready/rain.svg";
import partlyCloudyDayRain from "@bybas/weather-icons/design/fill/animation-ready/partly-cloudy-day-rain.svg";
import partlyCloudyNightRain from "@bybas/weather-icons/design/fill/animation-ready/partly-cloudy-night-rain.svg";
import partlyCloudyDaySnow from "@bybas/weather-icons/design/fill/animation-ready/partly-cloudy-day-snow.svg";
import partlyCloudyNightSnow from "@bybas/weather-icons/design/fill/animation-ready/partly-cloudy-night-snow.svg";
import thunderstormsRrain from "@bybas/weather-icons/design/fill/animation-ready/thunderstorms-rain.svg";
import fog from "@bybas/weather-icons/design/fill/animation-ready/fog.svg";
import thunderstormsDayRain from "@bybas/weather-icons/design/fill/animation-ready/thunderstorms-day-rain.svg";
import thunderstormsNightRain from "@bybas/weather-icons/design/fill/animation-ready/thunderstorms-night-rain.svg";
import sleet from "@bybas/weather-icons/design/fill/animation-ready/sleet.svg";
import thunderstormsSnow from "@bybas/weather-icons/design/fill/animation-ready/thunderstorms-snow.svg";
import partlyCloudyDaySleet from "@bybas/weather-icons/design/fill/animation-ready/partly-cloudy-day-sleet.svg";
import partlyCloudyNightSleet from "@bybas/weather-icons/design/fill/animation-ready/partly-cloudy-night-sleet.svg";
import snow from "@bybas/weather-icons/design/fill/animation-ready/snow.svg";
import thunderstormsDaySnow from "@bybas/weather-icons/design/fill/animation-ready/thunderstorms-day-snow.svg";
import thunderstormsNightSnow from "@bybas/weather-icons/design/fill/animation-ready/thunderstorms-night-snow.svg";
import thunderstormsRain from "@bybas/weather-icons/design/fill/animation-ready/thunderstorms-rain.svg";
import thermometerCelsius from "@bybas/weather-icons/design/fill/animation-ready/thermometer-celsius.svg";
import thermometerFahrenheit from "@bybas/weather-icons/design/fill/animation-ready/thermometer-fahrenheit.svg";
import thermometerKelvin from "@bybas/weather-icons/design/fill/animation-ready/thermometer-kelvin.svg";
import thermometerWarmer from "@bybas/weather-icons/design/fill/animation-ready/thermometer-warmer.svg";
import dustWind from "@bybas/weather-icons/design/fill/animation-ready/dust-wind.svg";
import raindrops from "@bybas/weather-icons/design/fill/animation-ready/raindrops.svg";
import uvIndex1 from "@bybas/weather-icons/design/fill/animation-ready/uv-index-1.svg";
import uvIndex2 from "@bybas/weather-icons/design/fill/animation-ready/uv-index-2.svg";
import uvIndex3 from "@bybas/weather-icons/design/fill/animation-ready/uv-index-3.svg";
import uvIndex4 from "@bybas/weather-icons/design/fill/animation-ready/uv-index-4.svg";
import uvIndex5 from "@bybas/weather-icons/design/fill/animation-ready/uv-index-5.svg";
import uvIndex6 from "@bybas/weather-icons/design/fill/animation-ready/uv-index-6.svg";
import uvIndex7 from "@bybas/weather-icons/design/fill/animation-ready/uv-index-7.svg";
import uvIndex8 from "@bybas/weather-icons/design/fill/animation-ready/uv-index-8.svg";
import uvIndex9 from "@bybas/weather-icons/design/fill/animation-ready/uv-index-9.svg";
import uvIndex10 from "@bybas/weather-icons/design/fill/animation-ready/uv-index-10.svg";
import uvIndex11 from "@bybas/weather-icons/design/fill/animation-ready/uv-index-11.svg";
import humidity from "@bybas/weather-icons/design/fill/animation-ready/humidity.svg";
import compass from "@bybas/weather-icons/design/fill/animation-ready/compass.svg";
import thermometerColder from "@bybas/weather-icons/design/fill/animation-ready/thermometer-colder.svg";
import { CSSProperties } from "react";


const codeMapping = {
  "clearsky_day": clearDay,
  "clearsky_night": clearNight,
  "clearsky_polartwilight": clearDay,
  "partlycloudy_day": partlyCloudyDay,
  "partlycloudy_night": partlyCloudyNight,
  "partlycloudy_polartwilight": partlyCloudyDay,
  "fair_day": partlyCloudyDay,
  "fair_night": partlyCloudyNight,
  "fair_polartwilight": partlyCloudyDay,
  "cloudy": cloudy,
  "heavyrain": rain,
  "heavyrainandthunder-day": thunderstormsRrain,
  "heavyrainandthunder-night": thunderstormsRrain,
  "heavyrainandthunder-polarwtilight": thunderstormsRrain,
  "heavyrainshowers-day": partlyCloudyDayRain,
  "heavyrainshowers-night": partlyCloudyNightRain,
  "heavyrainshowers-polarwtilight": partlyCloudyDayRain,
  "rainshowers-day": partlyCloudyDayRain,
  "rainshowers-night": partlyCloudyNightRain,
  "rainshowers-polarwtilight": partlyCloudyDayRain,
  "lightrainshowers-day": partlyCloudyDayRain,
  "lightrainshowers-night": partlyCloudyNightRain,
  "lightrainshowers-polarwtilight": partlyCloudyDayRain,
  "heavyrainshowersandthunder-day": thunderstormsDayRain,
  "heavyrainshowersandthunder-night": thunderstormsNightRain,
  "heavyrainshowersandthunder-polarwtilight": thunderstormsDayRain,
  "rainshowersandthunder-day": thunderstormsDayRain,
  "rainshowersandthunder-night": thunderstormsNightRain,
  "rainshowersandthunder-polarwtilight": thunderstormsDayRain,
  "lightrainshowersandthunder-day": thunderstormsDayRain,
  "lightrainshowersandthunder-night": thunderstormsNightRain,
  "lightrainshowersandthunder-polarwtilight": thunderstormsDayRain,
  "heavysleetandthunder": thunderstormsSnow,
  "heavysnowandthunder": thunderstormsSnow,
  "lightsnowandthunder": thunderstormsSnow,
  "lightsleetandthunder-day": thunderstormsSnow,
  "lightsleetandthunder-night": thunderstormsSnow,
  "lightsleetandthunder-polartwilight": thunderstormsSnow,
  "snowandthunder-day": thunderstormsSnow,
  "snowandthunder-night": thunderstormsSnow,
  "snowandthunder-polartwilight": thunderstormsSnow,
  "heavysnow": snow,
  "lightsnow": snow,
  "lightsnowshowers-day": partlyCloudyDaySnow,
  "lightsnowshowers-night": partlyCloudyNightSnow,
  "lightsnowshowers-polarwtilight": partlyCloudyDaySnow,
  "snowshowers-day": partlyCloudyDaySnow,
  "snowshowers-night": partlyCloudyNightSnow,
  "snowshowers-polarwtilight": partlyCloudyDaySnow,
  "sleet": sleet,
  "lightsleet": sleet,
  "sleetshowers-day": partlyCloudyDaySleet,
  "sleetshowers-night": partlyCloudyNightSleet,
  "sleetshowers-polartwilight": partlyCloudyDaySleet,
  "heavysleetshowers-day": partlyCloudyDaySleet,
  "heavysleetshowers-night": partlyCloudyNightSleet,
  "heavysleetshowers-polartwilight": partlyCloudyDaySleet,
  "lightsleetshowers-day": partlyCloudyDaySleet,
  "lightsleetshowers-night": partlyCloudyNightSleet,
  "lightsleetshowers-polartwilight": partlyCloudyDaySleet,
  "sleetshowersandthunder-day": thunderstormsSnow,
  "sleetshowersandthunder-night": thunderstormsSnow,
  "sleetshowersandthunder-polartwilight": thunderstormsSnow,
  "heavysleetshowersandthunder-day": thunderstormsSnow,
  "heavysleetshowersandthunder-night": thunderstormsSnow,
  "heavysleetshowersandthunder-polartwilight": thunderstormsSnow,
  "heavysnowshowersandthunder-day": thunderstormsDaySnow,
  "heavysnowshowersandthunder-night": thunderstormsNightSnow,
  "heavysnowshowersandthunder-polartwilight": thunderstormsDaySnow,
  "snowshowersandthunder-day": thunderstormsDaySnow,
  "snowshowersandthunder-night": thunderstormsNightSnow,
  "snowshowersandthunder-polartwilight": thunderstormsDaySnow,
  "lightssnowshowersandthunder-day": thunderstormsDaySnow,
  "lightssnowshowersandthunder-night": thunderstormsNightSnow,
  "lightssnowshowersandthunder-polartwilight": thunderstormsDaySnow,
  "lightssleetshowersandthunder-day": thunderstormsDaySnow,
  "lightssleetshowersandthunder-night": thunderstormsNightSnow,
  "lightssleetshowersandthunder-polartwilight": thunderstormsDaySnow,
  "heavysnowshowers-day": partlyCloudyDaySnow,
  "heavysnowshowers-night": partlyCloudyNightSnow,
  "heavysnowshowers-polartwilight": partlyCloudyDaySnow,
  "fog": fog,
  "lightrain": rain,
  "snow": snow,
  "lightrainandthunder": thunderstormsRain,
  "partlycloudy-day": partlyCloudyDay,
  "partlycloudy-night": partlyCloudyNight,
  "partlycloudy-polartwilight": partlyCloudyDay,
  "rainandthunder": thunderstormsRrain,
  "thermometer-celsius": thermometerCelsius,
  "thermometer-warmer": thermometerWarmer,
  "dust-wind": dustWind,
  "raindrops": raindrops,
  "uvIndex1": uvIndex1,
  "uvIndex2": uvIndex2,
  "uvIndex3": uvIndex3,
  "uvIndex4": uvIndex4,
  "uvIndex5": uvIndex5,
  "uvIndex6": uvIndex6,
  "uvIndex7": uvIndex7,
  "uvIndex8": uvIndex8,
  "uvIndex9": uvIndex9,
  "uvIndex10": uvIndex10,
  "uvIndex11": uvIndex11,
  "humidity": humidity,
  "compass": compass,
  "thermometer-colder": thermometerColder,
  "rain": rain,
}


export type WeatherIconsProps = {
  icon: typeof codeMapping[keyof typeof codeMapping],
  className?: string,
  style?: CSSProperties
}

function WeatherIcons(props: WeatherIconsProps) {
  const { icon, className, style } = props
  return (<img src={codeMapping[icon]} alt={icon.toString()} width={40} height={40} className={className} style={style} />)
}

export default WeatherIcons


