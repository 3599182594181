import { Route, Routes } from "react-router-dom";
import About from "./about";
import "./App.css"
import Navbar from "./components/Navbar";
import Home from "./home";

function App() {
  return (
    <div className="app">
      <Navbar />
      <div className="px-2 sm:px-4 py-2.5">
        <div className="container mx-auto">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default App;
