import axios from "axios"
import { useEffect, useState } from "react"
import convert from "xml-js"

export type LocalTempProps = {
  showWaterTemp?: boolean,
  showAirTemp?: boolean,
}

const LocalTemps = (props: LocalTempProps) => {
  const {
    showWaterTemp,
    showAirTemp,
  } = props
  const [waterTemp, setWaterTemp] = useState(0)
  const [airTemp, setAirTemp] = useState(0)

  useEffect(() => {
    axios
      .get(`https://api.allorigins.win/get?url=${encodeURIComponent("http://88.88.168.249:25380/values.xml")}`)
      .then((res) => {
        const json = convert.xml2js(res.data.contents)
        setWaterTemp(json.elements[0].elements[6].elements[2].elements[0].text)
        setAirTemp(json.elements[0].elements[7].elements[2].elements[0].text)
      }).catch((err) => {
        console.log(err)
      })
  })

  if (showWaterTemp) return (<>{waterTemp}</>)
  if (showAirTemp) return (<>{airTemp}</>)
  if (!showWaterTemp && !showAirTemp) return (<></>)
  if (showWaterTemp && !showAirTemp) return (<></>)
}

export default LocalTemps
