export type CnInput = Record<string, boolean | string> | string | string[]

function stringifyClassNames(input: CnInput) {
  if(input === null || input === undefined) return null
  let out = ""
  if(typeof input === "string") {
    out = input
  }
  if(Array.isArray(input)) {
    out = input.join(" ").trim()
  }
  else if(typeof input === "object") {
    out = Object.entries(input)
    .reduce((out, [ cn, inc ]) => {
      return !inc
        ? out
        : out === ""
          ? cn
          : `${out} ${cn}`
    }, "")
  }
  return out
}

function cn(...input: CnInput[]) {
  return input
    .filter(Boolean)
    .map(stringifyClassNames)
    .join(" ")
}

export default cn
