import { IoVideocamOffOutline } from "react-icons/io5"

function DashboardWebcam() {
  return (
    <div>
      <div className="rounded-t-lg flex h-[400px] bg-gray-700">
        <div className="m-auto flex"><IoVideocamOffOutline className="my-auto mr-2 text-red-500" /> Mangler webcam feed</div>
      </div>
      <div className="rounded-b-lg p-6 bg-gray-800">
        Webcameraet er plassert på Dillingøy i retning Store Kvernøy. Vanstanden hentes løpende fra målerstasjonen ved Rødsund Bru og måles opp mot middel regulert vannstand for perioden. Temperaturer måles ved gjestebrygga på Dillingøy.
      </div>
    </div>
  )
}

export default DashboardWebcam