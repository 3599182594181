import React from "react"
import Weather from "./components/Weather/Weather"
import DashboardWebcam from "./DashboardWebcam/DashboardWebcam"

function Home() {
  return (
    <div>
      <div className="flex gap-4 flex-col sm:flex-row">
        <div className="w-full sm:w-6/12 lg:w-8/12 flex flex-col gap-4">
          <DashboardWebcam />
        </div>
        <div className="w-full sm:w-6/12 lg:w-4/12">
          <Weather />
        </div>
      </div>
    </div>
  )
}

export default Home;