import { NavLink } from "react-router-dom"
import cn from "../utils/utils.cn"
import logo from "./logo.svg"

function Navbar() {

  const activeClassName = "text-white bg-blue-700 rounded md:bg-transparent md:text-white md:p-0 text-white"
  const staleClassName = "text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-gray-400 md:hover:text-white hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700"

  return (
    <nav className="border-gray-200 px-2 sm:px-4 py-2.5 rounded-b bg-gray-800">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <NavLink to="/" className="flex items-center">
          <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
          <span className="self-center text-xl font-semibold whitespace-nowrap text-white">Vansjø.info</span>
        </NavLink>
        {/* <button data-collapse-toggle="mobile-menu" type="button" className="inline-flex items-center p-2 ml-3 text-sm rounded-lg md:hiddenfocus:outline-none focus:ring-2 text-gray-400 hover:bg-gray-700 focus:ring-gray-600" aria-controls="mobile-menu" aria-expanded="false">
          <span className="sr-only">Open main menu</span>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
          <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button> */}
        <div className="hidden w-full md:block md:w-auto" id="mobile-menu">
          <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
            <li>
              <NavLink to="/"
              className={({ isActive }) =>
                  cn("block py-2 pr-4 pl-3",
                    isActive
                      ? activeClassName
                      : staleClassName
                  )}>
                Vann og vær
              </NavLink>
            </li>
            <li>
              <NavLink to="jakt-og-fisk"
                className={({ isActive }) =>
                  cn("block py-2 pr-4 pl-3",
                    isActive
                      ? activeClassName
                      : staleClassName
                  )}>
                Jakt og fisk
              </NavLink>
            </li>
            <li>
              <NavLink to="bruk-av-innsjo"
                className={({ isActive }) =>
                  cn("block py-2 pr-4 pl-3",
                    isActive
                      ? activeClassName
                      : staleClassName
                  )}>
                Bruk av innsjø
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
