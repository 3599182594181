import Card from "../Card"
import yr from "yr-forecast"
import { useEffect, useState } from "react"
import WeatherIcons from "./WeatherIcons"
import cn from "../../utils/utils.cn"
import LocalTemps from "./LocalTemp"
import WaterLevel from "./WaterLevelCard"

function Weather() {
  const [weatherData, setWeatherData] = useState(null)
  const [uvIndexNow, setUvIndexNow] = useState(null)
  const [humidityNow, setHumidityNow] = useState(null)
  const [windSpeedNow, setWindSpeedNow] = useState(null)
  const [windDirectionNow, setWindDirectionNow] = useState(null)
  const [tempratureNow, setTempratureNow] = useState(null)
  const [windGustNow, setWindGustNow] = useState(null)
  const [airPressurenow, setAirPressurenow] = useState(null)
  const [groupedWeatherData, setGroupedWeatherData] = useState(null)
  const [probabilityOfPrecipitationNow, setProbabilityOfPrecipitationNow] = useState(null)
  const [localAirTempratureNow, setLocalAirTempratureNow] = useState(0)
  const [localWaterTempratureNow, setLocalWaterTempratureNow] = useState(0)
  const [precipitationAmountNow, setPrecipitationAmountNow] = useState(null)
  const [probabilityOfThunderNow, setProbabilityOfThunderNow] = useState(null)


  const lat = 59.2667259
  const lon = 10.4045301

  const checkIfDateIsBetweenTwoDatesV2 = (input: Date) => {
    const startDate = new Date()
    startDate.setHours(0)
    const endDate = new Date()
    endDate.setHours(23, 59, 59, 0)
    return input >= startDate && input <= endDate
  }

  const checkIfHourIsNow = (date: Date) => {
    const now = new Date()
    return date.getHours() === now.getHours()
  }

  const addZeroToDateOrTime = (date: number) => {
    return date < 10 ? `0${date}` : date
  }

  useEffect(() => {
    ; (async () => {
      try {
        const response = yr.getForecast(lat, lon)
        const data = await response.then(res => res)
        setWeatherData(data)
        const groups = data.reduce((groups, item) => {
          const date = new Date(item.from).toISOString().split("T")[0]
          if (!groups[date]) {
            groups[date] = []
          }
          groups[date].push(item)
          return groups
        }, {} as { [key: string]: any[] })
        const groupArrays = Object.keys(groups).map(item => {
          console.log(new Date(item))
          if (checkIfDateIsBetweenTwoDatesV2(new Date(item)) && checkIfHourIsNow(new Date())) {
            setAirPressurenow(groups[item][0].instant.air_pressure_at_sea_level)
            setHumidityNow(groups[item][0].instant.relative_humidity)
            setTempratureNow(groups[item][0].instant.air_temperature)
            setWindDirectionNow(groups[item][0].instant.wind_from_direction)
            setWindSpeedNow(groups[item][0].instant.wind_speed)
            setWindGustNow(groups[item][0].instant.wind_speed_of_gust)
            setProbabilityOfPrecipitationNow(groups[item][0].next_1_hours.probability_of_precipitation)
            setUvIndexNow(Math.round(groups[item][0].instant.ultraviolet_index_clear_sky))
            setPrecipitationAmountNow(groups[item][0].next_1_hours.precipitation_amount)
            setProbabilityOfThunderNow(groups[item][0].next_1_hours.probability_of_thunder)
          }
          return {
            item,
            minTemp: Math.min(...groups[item].map(item => item.instant.air_temperature)),
            maxTemp: Math.max(...groups[item].map(item => item.instant.air_temperature)),
            averageWind: Math.round(
              groups[item].reduce((sum, item) => sum + item.instant.wind_speed, 0) / groups[item].length
            ),
            averageWindDirection: Math.round(
              groups[item].reduce((sum, item) => sum + item.instant.wind_from_direction, 0) / groups[item].length
            ),
            precipitationAmount: groups[item].reduce((sum, item) => sum + item.next_6_hours?.precipitation_amount, 0) || 0,
            daySymbolCode: groups[item][0].next_6_hours?.symbol_code || "cloudy",
            weatherData: groups[item]
          }
        })
        setGroupedWeatherData(groupArrays)
      }
      catch (err) {
        console.error(err)
        setWeatherData(null)
      }
    })()
  }, [])

  const getTimeFromDate = (date: Date) => {
    return addZeroToDateOrTime(date.getHours()) + ":" + addZeroToDateOrTime(date.getMinutes())
  }

  const hourPerception = weatherData?.slice(0, 27)

  const hourlyWeatherRender = () => {
    return hourPerception
      ? hourPerception?.map((weather, index) => <div className="flex flex-col min-w-[3.091rem]" key={index}>
        <div className="mx-auto"><WeatherIcons icon={weather.next_1_hours?.symbol_code} /></div>
        <div className="mx-auto text-sm">{weather.instant.air_temperature}°C</div>
        <div className="mx-auto text-xs font-bold">
          {checkIfHourIsNow(weather.from) ? "Nå" : getTimeFromDate(weather.from)}
        </div>
      </div>)
      : <>Loading</>
  }

  const renderTodaysDateWithTime = () => {
    const date = new Date()
    return `${addZeroToDateOrTime(date.getDate())}.${addZeroToDateOrTime(date.getMonth() + 1)}.${date.getFullYear()} kl. ${getTimeFromDate(date)}`
  }

  const renderDate = (date: Date) => {
    date = new Date(date)
    return `${addZeroToDateOrTime(date.getDate())}.${addZeroToDateOrTime(date.getMonth() + 1)}`
  }

  const uvIndexRender = () => (<Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
    <div className="text-left text-white/60 text-xs font-bold">UV Index</div>
    <div className="text-center text-sm font-semibold m-auto">
      {uvIndexNow > 0 ? <WeatherIcons icon={`uvIndex${uvIndexNow}`} /> : uvIndexNow}
    </div>
  </Card>)

  const humidityRender = () => <Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
    <div className="text-left text-white/60 text-xs font-bold">Luftfuktighet</div>
    <div className="text-center text-sm font-semibold m-auto flex">
      {humidityNow}%
    </div>
  </Card>

  const airPressureRender = () => (<Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
    <div className="text-left text-white/60 text-xs font-bold">Lufttrykk</div>
    <div className="text-center text-sm font-semibold m-auto flex">
      {airPressurenow} hPa
    </div>
  </Card>)

  const windRender = () => (<Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
    <div className="text-left text-white/60 text-xs font-bold">Vind</div>
    <div className="text-center text-sm font-semibold m-auto flex">
      {windSpeedNow} m/s
    </div>
  </Card>)

  const windGustRender = () => (<Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
    <div className="text-left text-white/60 text-xs font-bold">Vindkast</div>
    <div className="text-center text-sm font-semibold m-auto flex">
      {windGustNow} m/s
    </div>
  </Card>)

  const windDirectionRender = () => {
    return windDirectionNow
      ? <Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
        <div className="text-left text-white/60 text-xs font-bold">Vindretning</div>
        <div className="text-center text-lg font-semibold m-auto flex w-full h-full relative flex">
          <WeatherIcons icon={"compass"}
            className={cn("w-[3.125rem] h-[3.125rem] m-auto")}
            style={{ transform: `rotate(${+windDirectionNow.toString().split(".")[0] - 220}deg)` }}
          />
        </div>
      </Card>
      : <>Laster...</>
  }

  const localAirTempratureRender = () => (<Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
    <div className="text-left text-white/60 text-xs font-bold">Lufttemperatur</div>
    <div className="text-center text-sm font-semibold m-auto flex">
      {tempratureNow}°C / <LocalTemps showAirTemp={true} />°C
    </div>
  </Card>)

  const localWaterTempratureNowRender = () => (<Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
    <div className="text-left text-white/60 text-xs font-bold">Vanntemperatur</div>
    <div className="text-center text-sm font-semibold m-auto flex">
      <LocalTemps
        showWaterTemp={true}
      />°C
    </div>
  </Card>)

  const precipitationAmountNowRender = () => (<Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
    <div className="text-left text-white/60 text-xs font-bold">Nedbør</div>
    <div className="text-center text-sm font-semibold m-auto flex">
      {precipitationAmountNow} mm / {probabilityOfPrecipitationNow}%
    </div>
  </Card>)

  const probabilityOfThunderNowRender = () => (<Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
    <div className="text-left text-white/60 text-xs font-bold">Torden sansynlighet</div>
    <div className="text-center text-sm font-semibold m-auto flex">
      {probabilityOfThunderNow}%
    </div>
  </Card>)

  const abnormalWaterLevelRender = () => (<Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
    <div className="text-left text-white/60 text-xs font-bold">Vannhøyde abnormal</div>
    <div className="text-center text-sm font-semibold m-auto flex">
      <WaterLevel showAbnormalWaterLevel={true} /> cm
    </div>
  </Card>)

  const currentWaterLevelRender = () => (<Card className="inline-flex flex-col !p-2 h-[4.5rem] !bg-gray-700 !border-transparent">
    <div className="text-left text-white/60 text-xs font-bold">Vannhøyde</div>
    <div className="text-center text-sm font-semibold m-auto flex">
      <WaterLevel showCurrentWaterLevel={true} /> moh
    </div>
  </Card>)


  const nineDayPerceptionRender = () => {
    return groupedWeatherData
      ? groupedWeatherData.map((weather, index) => (
        <Card className="!bg-gray-700 !border-transparent !p-2" key={index}>
          <div className="flex flex-row w-full relative">
            <div className="flex mr-auto font-bold flex-col">
              <div className="my-auto first-letter:uppercase ">{new Date(weather.item).toLocaleDateString("no-nb", { weekday: 'long' })}</div>
              <div className="text-xs text-center font-normal">{renderDate(weather.item)}</div>
            </div>
            <div className="flex m-auto flex-row font-bold">
              <div className="text-xs flex"><WeatherIcons className="w-[30px] scale-[1.3]" icon="thermometer-warmer" /><div className="my-auto">{weather.maxTemp} °C</div></div>
              <div className="text-xs flex"><WeatherIcons className="w-[30px] scale-[1.3]" icon="thermometer-colder" /><div className="my-auto">{weather.minTemp} °C</div></div>
            </div>
            <div className="flex ml-auto"><WeatherIcons className="" icon={weather.daySymbolCode || "nan"} /></div>
          </div>
        </Card>
      ))
      : <>Loading</>
  }


  return (
    <Card className="flex flex-col gap-4 !p-4">
      <div className="flex flex-col gap-4">
        <div className="font-semibold text-base flex">Akkurat nå <span className="text-white/90 ml-auto text-sm">{renderTodaysDateWithTime()}</span></div>
        <div className="grid grid-rows-4 sm:grid-rows-6 lg:grid-rows-6 xl:grid-rows-4 grid-flow-col gap-3">
          {localWaterTempratureNowRender()}
          {localAirTempratureRender()}
          {uvIndexRender()}
          {windRender()}
          {windGustRender()}
          {windDirectionRender()}
          {airPressureRender()}
          {precipitationAmountNowRender()}
          {humidityRender()}
          {probabilityOfThunderNowRender()}
          {abnormalWaterLevelRender()}
          {currentWaterLevelRender()}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="font-semibold text-base text-gray-300">Timesvarsel</div>
        <div className="flex gap-x-2 overflow-auto pb-4">
          {hourlyWeatherRender()}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="font-semibold text-base text-gray-300">Langtidsvarsel</div>
        {nineDayPerceptionRender()}
      </div>
    </Card>
  )
}

export default Weather